<template>
  <div>
    <GridRow v-if="box.relationships.articles[0].type === 'Dossier'" class="dossier-teaser">
      <SpunqImage
        v-if="article.attributes.teaser.image"
        :image="article.attributes.teaser.image"
        :width="1440"
        :height="720"
        :mobile-width="768"
        :mobile-height="768"
        mode="crop"
        class="_image"
        :lazy="true"
      />
      <div class="dossier-teaser-content" :class="{ darkmode: isDarkmode }">
        <div class="_header">
          <div class="dossier-teaser__badges" v-if="article.attributes.payWall === 1 || article.attributes.onlineFirst">
            <ContentTypeBadge :f-plus="article.attributes.payWall" :online-first="article.attributes.onlineFirst" />
          </div>
          <div class="_header-link-hook">
            <h2 class="_title">
              <a v-snip="3" class="_header-link" :href="dossierLink">
                {{ article.attributes.teaser.title }}
              </a>
            </h2>
            <p v-if="article.attributes.teaser.leadText" class="_leadtext">
              {{ article.attributes.teaser.leadText }}
            </p>
          </div>
          <p v-if="article.attributes.isFocus && article.attributes.frontendDate" class="_date">
            {{ fontendDate }}
          </p>
          <Redaction
            v-if="article.relationships.authors.length > 0 && article.attributes.isFocus"
            :authors="article.relationships.authors"
            :article-type="article.type"
          />
          <ShareBar
            :share-title="article.attributes.teaser.title"
            :share-on="['facebook', 'twitter', 'mailto']"
            :darkmode="isDarkmode"
            class="_sharebar"
          />
        </div>
        <div v-if="article.relationships.articles && article.relationships.articles.length > 0" class="_previews">
          <TeaserSliderHero :article="article" :lazy="true" />
        </div>
      </div>
    </GridRow>
  </div>
</template>

<script>
import Moment from 'moment'
import SpunqImage from '@/components/ui/image/spunq-image'
import GridRow from '@/components/ui/grid/row'
import ShareBar from '@/components/ui/socialmedia/sharing'
import Redaction from '@/components/ui/author/redaction'
import TeaserSliderHero from '@/components/ui/slider/teaser-slider-hero'
import ContentTypeBadge from '@/components/ui/badge/content-type'

export default {
  components: {
    GridRow,
    SpunqImage,
    ShareBar,
    Redaction,
    TeaserSliderHero,
    ContentTypeBadge,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    article() {
      return this.box.relationships.articles[0]
    },
    fontendDate() {
      return Moment(this.article.attributes.frontendDate).format('DD. MMMM YYYY')
    },
    isDarkmode() {
      // return false
      return this.article.attributes.background === 1
    },
    dossierLink() {
      const channels = [
        ...this.article.relationships.channels[0].relationships.parents,
        this.article.relationships.channels[0],
      ]
      const channelSlugs = channels.map((channel) => channel.attributes.slug).filter((slug) => !!slug)

      return `/${channelSlugs.join('/')}`
    },
  },
}
</script>
<style lang="scss">
@import '../../../../css/import/vars';
.dossier-teaser {
  position: relative;
  padding: 20px;

  &__badges {
    width: auto;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }

  ._image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: $screen-width-1023) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media screen and (min-width: $screen-width-1440) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.dossier-teaser-content {
  &.darkmode {
    .author__link,
    ._header {
      color: $color-white;
    }

    .authors-redaction__redaction {
      color: rgba($color-white, 0.6);
    }
  }

  ._header {
    color: $color-text-dark-gray;

    &-link-hook {
      position: relative;
    }

    &-link {
      color: currentColor;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    p {
      font-variation-settings: 'wght' 600;
      font-weight: 600;
      color: currentColor;
    }

    ._title {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 34px;
      color: currentColor;
    }

    ._leadtext {
      margin-bottom: 10px;
      font-size: 17px;
      line-height: 25px;
    }

    ._date {
      font-size: 14px;
    }

    ._sharebar {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  ._previews {
    padding-top: 24px;
  }

  @media screen and (min-width: $screen-width-767) {
    display: flex;
    width: 100%;

    ._header {
      display: flex;
      flex-basis: 40%;
      flex-grow: 0;
      flex-shrink: 0;
      flex-direction: column;

      > * {
        width: 100%;

        &:last-child {
          margin-top: auto;
        }
      }
    }

    ._previews {
      overflow: hidden;
      display: flex;
      flex-basis: 60%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  @media screen and (min-width: $screen-width-767) {
    ._header {
      ._title {
        font-size: 50px;
        line-height: 1.08;
      }
    }
  }
}
</style>
