// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/svg/icon-quote.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider-teaser-opinion{background-color:hsla(0,0%,100%,.8);display:flex;flex-direction:column;height:100%;justify-content:space-between;padding:20px;position:relative;width:100%}@media (min-width:767px){.slider-teaser-opinion{max-width:200px}}.slider-teaser-opinion__badges{align-items:center;display:flex;gap:5px;justify-content:center;margin-bottom:5px;width:auto;width:100%}.slider-teaser-opinion__title{font-size:1.375rem;line-height:1.5rem;margin-bottom:10px;width:100%}.slider-teaser-opinion__title:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;content:\" \";display:block;height:30px;margin-bottom:1rem;text-align:center;vertical-align:text-bottom;width:100%}@media (min-width:767px){.slider-teaser-opinion__title{font-size:1.25rem}}.slider-teaser-opinion__link{color:#2c2c2d;display:block;text-align:center;transition:color .7s;width:100%}.slider-teaser-opinion__link:before{content:\"\";height:100%;left:0;position:absolute;top:0;width:100%;z-index:2}.slider-teaser-opinion__footer{display:flex;font-size:.8125rem;font-variation-settings:\"wght\" 600;font-weight:600;justify-content:center;padding-top:20px;width:100%}@media (min-width:767px){.slider-teaser-opinion__footer{font-size:.75rem}}.slider-teaser-opinion__author{color:#4b4b4d}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
