<template>
  <div class="hint-tag">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.hint-tag {
  box-sizing: border-box;
  background: $color-red;
  color: $color-white;
  font-size: 12px;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 2px 5px;
}
</style>
