<template>
  <article class="slider-teaser-opinion">
    <div
      class="slider-teaser-opinion__badges"
      v-if="article.attributes.payWall === 1 || article.attributes.onlineFirst"
    >
      <ContentTypeBadge :f-plus="article.attributes.payWall" :online-first="article.attributes.onlineFirst" />
    </div>
    <h2 v-if="article.attributes.teaser.title" class="slider-teaser-opinion__title">
      <ArticleLink
        v-snip="3"
        :article="article"
        :title="article.attributes.teaser.title"
        class="slider-teaser-opinion__link"
      >
        {{ article.attributes.teaser.title }}
      </ArticleLink>
    </h2>
    <footer class="slider-teaser-opinion__footer">
      <span v-if="article.relationships.authors.length > 0" class="slider-teaser-opinion__author">
        {{ article.relationships.authors[0].attributes.firstName }}
        {{ article.relationships.authors[0].attributes.lastName }}
      </span>
    </footer>
  </article>
</template>

<script>
import ArticleLink from '@/components/article/link'
import ContentTypeBadge from '@/components/ui/badge/content-type'

export default {
  components: {
    ArticleLink,
    ContentTypeBadge,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';
.slider-teaser-opinion {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  @media all and (min-width: $screen-width-767) {
    max-width: 200px;
  }

  &__badges {
    width: auto;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
  }

  &__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;
    width: 100%;

    &:before {
      content: ' ';
      width: 100%;
      height: 30px;
      text-align: center;
      background-image: url('~assets/svg/icon-quote.svg');
      background-repeat: no-repeat;
      background-position: center center;
      display: block;
      margin-bottom: 1rem;
      vertical-align: text-bottom;
    }

    @media all and (min-width: $screen-width-767) {
      font-size: 20px;
    }
  }

  &__link {
    width: 100%;
    display: block;
    text-align: center;
    color: $color-text-dark-gray;
    transition: color 0.7s;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__footer {
    padding-top: 20px;
    font-size: 13px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    display: flex;
    justify-content: center;
    width: 100%;

    @media all and (min-width: $screen-width-767) {
      font-size: 12px;
    }
  }
  &__author {
    color: $color-text;
  }
}
</style>
